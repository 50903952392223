<template>
    <div class="home">
      <b-container id="styledElement">
            <div class="elemnts-box" id="resizeElement">
                <div class="scoreboard-section">
                  <div class="score-board">scoreboard</div>
                    <b-row>
                        <b-col class="text-center">
                          <ul v-if="Array.isArray(scoreboard)" class="scoreboard-list">
                            <li v-for="(score, idx) in scoreboard" :key="idx">
                              <span class="number">{{ idx + 1 }}</span>
                              <span class="img"><img v-if="Number.isInteger(score.avatar)" :src="getAvatar(score.avatar)"></span>
                              <span class="name">{{ score.username }}</span>
                              <span class="score-list">{{ score.score }}/{{ totalQuestions }}</span>
                            </li>
                          </ul>
                        </b-col>
                    </b-row>
                    <div class="icons-holder"></div>
                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
import questions from '../assets/quiz.json'
import { mapGetters, mapActions } from 'vuex'
import avatars from '../avatars'

export default {
  name: 'ScoreBoard',
  computed: {
    ...mapGetters(['scoreboard']),
    totalQuestions () {
      return questions.length
    }
  },
  data () {
    return {
      avatars
    }
  },
  methods: {
    ...mapActions(['setScoreBoard']),
    getAvatar (idx) {
      try {
        return avatars[idx]
      } catch (e) {
        return ''
      }
    }
  },
  mounted () {
    this.setScoreBoard()
  }
}

</script>
