<template>
  <div class="home">
    <b-container id="styledElement">
      <div class="elemnts-box" id="resizeElement">
        <span class="green-shape-right"></span>
        <div class="white-box" style="margin-bottom: 0; padding-top: 0; padding-bottom: 12px">
          <span class="white-content-bg"></span>
          <b-row>
            <b-col class="text-center">
              <div class="score">
                <div class="score-holder">
                  <h4>You Scored</h4>
                  <h4>{{ score }} / {{ totalQuestions }}</h4>
                </div>
                <h3 class="score-message">{{ scoreMessage }}</h3>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="blue-bg" style="margin-top: -10px; padding-top: 30px; padding-bottom: 65px;">
          <p class="share-msg">Share your score to<br>
            be featured on our scoreboard…</p>
          <div class="avatar-row">
            <b-row>
              <b-col class="col-7">
                <div class="avatar">
                  <img :src="avatar">
                </div>
              </b-col>
              <b-col class="text-center col-5" style="padding: 0 15px 0 0">
                <div class="score-det">
                  <h3>{{ userName }}</h3>
                  <h4>{{ score }} / {{ totalQuestions }}</h4>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col class="text-center">
              <div class="share-box">
                <h3>SHARE:</h3>
                <a :href="'https://www.facebook.com/dialog/share?app_id=520082279304078&display=popup&href=https://www.dirtisgoodacademychallenge.com/&title=Dirt Is Good Academy&quote=' + socialMediaScoreMessage" target="_blank"><img src="../assets/img/fb.png"></a>
                <a :href="'https://twitter.com/intent/tweet?text=' + socialMediaScoreMessage + encodeURIComponent(' https://www.dirtisgoodacademychallenge.com')" target="_blank"><img src="../assets/img/tw.png"></a>
                <a :href="'https://wa.me/?text=' + socialMediaScoreMessage + encodeURIComponent(' https://www.dirtisgoodacademychallenge.com')" target="_blank"><img src="../assets/img/wa.png"></a>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <div class="score">
                <b-btn class="view-score" :to="{ name: 'ScoreBoard' }">VIEW THE SCOREBOARD</b-btn>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import questions from '../assets/quiz.json'
import { mapGetters } from 'vuex'

export default {
  name: 'Score',
  computed: {
    ...mapGetters(['score', 'userName', 'avatar']),
    totalQuestions () {
      return questions.length
    },
    scoreMessage () {
      if (this.score <= 5) {
        return 'Time to go back to school?! Try again and see if you can beat your score, we all love a trier… '
      } else if (this.score <= 10) {
        return 'Not bad but there is always room to improve! Do some swotting up for next time! '
      } else {
        return 'You’re a lean, green, eco machine! Nice work, time to put that knowledge into action!'
      }
    },
    socialMediaScoreMessage () {
      if (this.score < 10) {
        return encodeURIComponent('I just found out that I’m not greener than a 7-year-old in Dirt Is Good ft. Unilever Future Leaders’ League Challenge What about you? Take the test and share your results using #UFLL #DIGChallenge”')
      } else {
        return encodeURIComponent('I just found out that I’m as green as a 7-year-old in Dirt Is Good ft. Unilever Future Leaders’ League Challenge What about you? Take the test and share your results using #UFLL #DIGChallenge”')
      }
    }
  },
  mounted () {
    if (this.userName.length === 0) {
      this.$router.push({ name: 'Avatar' })
    }
  }
}
</script>
