<template>
    <div class="avatarGame info-template">
      <b-container id="styledElement">
                <div class="text-center" id="resizeElement">
                    <div class="elemnts-box">
                        <span class="shape-left"></span>
                        <span class="shape-right"></span>
                        <div class="white-box home-avatar-icons">
                            <span class="mobile-green-bottom"></span>
                            <span class="mobile-footer-bottom"></span>
                            <span class="white-content-bg"></span>
                            <perfect-scrollbar :options="{ suppressScrollX: true }" class="info-wrapper">
                              <div>Test</div>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </div>
            </b-container>
        </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>

<style scoped>
    .info-wrapper {
        overflow-y: scroll;
        height: 400px;
        margin-bottom: 30px;
        padding-right: 15px;
    }

    .loader {
        padding-top: 20%;
    }

    .avatarGame .white-box {
        padding: 60px 60px 30px;
    }
</style>
